<template>
  <section id="app" class="hero">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4" v-show="username != ''">
          <h3 class="title">Sign up</h3>
          <div class="box">
            <section>
              <b-notification :closable="false" v-show="notification != ''">
                {{ notification }}
              </b-notification>
              <b-field label="E-mail" label-position="on-border">
                <b-input
                  ref="username"
                  name="username"
                  v-model="username"
                  type="email"
                  disabled="true"
                ></b-input>
              </b-field>
              <section
                class="field"
                v-show="!password_verified && !user_created"
              >
                <b-field label="Wachtwoord" label-position="on-border">
                  <b-input
                    ref="password"
                    name="password"
                    v-model="password"
                    type="password"
                  ></b-input>
                </b-field>
                <b-field
                  label="Wachtwoord verificatie"
                  label-position="on-border"
                >
                  <b-input
                    ref="password_verification"
                    name="password_verification"
                    v-model="password_verification"
                    type="password"
                  ></b-input>
                </b-field>
              </section>
              <section
                class="field"
                v-show="password_verified && !user_created"
              >
                <b-field>
                  <b-image style="is-bordered" :src="qr" />
                </b-field>
                <b-field label="2FA" label-position="on-border">
                  <b-input
                    ref="code"
                    name="code"
                    v-model="code"
                    type="number"
                  ></b-input>
                </b-field>
              </section>
              <b-field v-show="!user_created">
                <b-button
                  :loading="loading"
                  :disabled="loading"
                  @click.prevent="signup"
                  class="is-block is-info is-fullwidth"
                  label="Sign up"
                ></b-button>
              </b-field>
              <b-field v-show="user_created">
                <b-button
                  :loading="loading"
                  :disabled="loading"
                  @click.prevent="finished"
                  class="is-block is-info is-fullwidth"
                  label="Naar inlog pagina"
                ></b-button>
              </b-field>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data: function () {
    return {
      notification: "",
      username: "",
      password: "",
      password_verification: "",
      password_verified: false,
      user_created: false,
      loading: false,
      code: "",
      style: "",
    };
  },
  mounted: function () {
    this.signup();
  },
  computed: {
    qr: function () {
      return this.password_verified
        ? "/login/" + this.$route.params.id + "/qr.png"
        : "/login/" + this.$route.params.id + "/404.png";
    },
  },
  methods: {
    signup: function () {
      this.loading = true;
      var self = this;
      var params = new URLSearchParams();
      params.append("password", this.password);
      params.append("password_verification", this.password_verification);
      params.append("code", this.code);
      axios
        .post(window.location.href, params, { withCredentials: true })
        .then(
          function (response) {
            self.username = response.data.username;
            self.password_verified = response.data.password_verified;
            self.user_created = response.data.user_created;
            self.notification = response.data.notification;
            self.loading = false;
          },
          function (error) {
            if (error.response) {
              self.notification = error.response.data.notification;
            }
            self.password_verified = false;
            self.user_created = false;
            self.loading = false;
          }
        )
        .catch(function (error) {
          console.log(error);
          self.password_verified = false;
          self.user_created = false;
          self.loading = false;
        });
    },
    finished: function () {
      this.loading = true;
      window.location.href = "/";
    },
  },
};
</script>
