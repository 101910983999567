<template>
  <section id="app" class="hero">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <h3 class="title">Project Monkey</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
