<template>
  <section id="app" class="hero">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <h3 class="title">Login</h3>
          <div class="box">
            <section>
              <b-notification :closable="false" v-show="notification != ''">
                {{ notification }}
              </b-notification>
              <b-field label="E-mail" label-position="on-border">
                <b-input
                  ref="username"
                  name="username"
                  v-model="username"
                  type="email"
                  @keyup.enter="loginAfterEnter"
                ></b-input>
              </b-field>
              <b-field
                label="Wachtwoord"
                label-position="on-border"
                v-show="counter > 0"
              >
                <b-input
                  ref="password"
                  name="password"
                  v-model="password"
                  type="password"
                  @keyup.enter="loginAfterEnter"
                ></b-input>
              </b-field>
              <b-field
                label="2FA"
                label-position="on-border"
                v-show="counter > 1"
              >
                <b-input
                  ref="code"
                  name="code"
                  v-model="code"
                  type="number"
                  @keyup.enter="loginAfterEnter"
                ></b-input>
              </b-field>
              <b-button
                @click.prevent="login"
                class="is-block is-info is-fullwidth"
                :loading="loggingIn"
                :disabled="loggingIn"
                :label="button"
              ></b-button>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data: function () {
    return {
      notification: "",
      username: "",
      password: "",
      code: "",
      loggingIn: false,
      button: "Next",
      counter: 0,
      visible: true,
    };
  },
  mounted: function () {
    this.$refs.username.focus();
  },
  methods: {
    loginAfterEnter: function (event) {
      console.log("loginafterenter");
      console.log(event);
      if (event.target.value != "") {
        this.login();
      }
    },
    login: function () {
      this.loggingIn = true;
      if (this.counter > 0) {
        this.button = "Login";
      }
      var self = this;
      var params = new URLSearchParams();
      params.append("username", this.username);
      params.append("password", this.password);
      params.append("code", this.code);
      axios
        .post(window.location.href, params, { withCredentials: true })
        .then(
          function (response) {
            self.counter++;
            if (self.counter == 1) {
              self.$refs.password.focus();
            }
            if (self.counter == 2) {
              self.$refs.code.focus();
            }
            self.notification = response.data.notification;
            if (response.data.phase == 2) {
              window.location.href = "/";
            } else {
              self.loggingIn = false;
            }
          },
          function (error) {
            if (error.response) {
              self.notification = error.response.data.notification;
            }
            self.loggingIn = false;
          }
        )
        .catch(function (error) {
          console.log(error);
          self.loggingIn = false;
        });
    },
  },
};
</script>
