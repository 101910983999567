import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import Buefy from "buefy";
import "buefy/dist/buefy.css";

import App from "./App.vue";
import LoginView from "./LoginView.vue";
import SignupView from "./SignupView.vue";
import ProjectsView from "./ProjectsView.vue";

const routes = [
  { path: "/login", component: LoginView },
  { path: "/login/:id", component: SignupView },
  { path: "/", component: ProjectsView },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.use(Buefy);
app.mount("#app");
